<template>
  <div>
    <h1>Show</h1>
    <div>
      <pre>{{ legal_person | pretty_json }}</pre>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState("legal_people", ["legal_person"]),
  },
  methods: {
    ...mapActions("legal_people", ["ActionShow"]),
  },
  async created() {
    await this.ActionShow({id: this.$route.params.id});
  },
};
</script>

<style>
</style>
